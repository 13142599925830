<template>
<!-- Table Container Card -->
<b-card no-body title="Invoice">

    <div class="m-2">

        <!-- Table Top -->
        <b-row>

            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>Entries</label>
                <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />

            </b-col>

            <!-- Search -->
            <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                    <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
                    <v-select v-model="statusFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions" class="invoice-filter-select" placeholder="Select Status">
                        <template #selected-option="{ label }">
                            <span class="text-truncate overflow-hidden">
                                {{ label }}
                            </span>
                        </template>
                    </v-select>
                </div>
            </b-col>
        </b-row>

    </div>

    <b-table ref="refInvoiceListTable" :items="invoices" responsive :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc" class="position-relative">

        <template #head(status)>
            <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <!-- Column: Id -->
        <template #cell(invoiceId)="data">
            <b-link :to="{ name: 'apps-invoice-preview', params: { id: data.item.invoiceId }}" class="font-weight-bold">
                #{{ data.item.invoiceId }}
            </b-link>
        </template>
        <template #cell(amount)="data">
            <b-link :to="{ name: 'apps-invoice-preview', params: { id: data.item.invoiceId }}" class="font-weight-bold">
                {{data.item.currency}} {{ data.item.amount | formatAmount}}
            </b-link>
        </template>

        <!-- Column: Invoice Status -->
        <template #cell(status)="data">
            <b-avatar :id="`invoice-row-${data.item.invoiceId}`" size="32" :variant="`light-${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`">
                <feather-icon :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon" />
            </b-avatar>
            <b-tooltip :target="`invoice-row-${data.item.invoiceId}`" placement="top">
                <p class="mb-0">
                    Invoice Status {{ data.item.status | InvoiceStatus}}
                </p>
                <p class="mb-0">
                    Amount: {{ data.item.amount | formatAmount}}
                </p>
                <p class="mb-0">
                   Due Date: {{ data.item.due_date | formatDate}}
                </p>
            </b-tooltip>
        </template>

        <!-- Column: Client -->
        <template #cell(client)="data">
            <b-media vertical-align="center">
                <template #aside>
                    <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.full_names)" :variant="`light-${resolveClientAvatarVariant(data.item.invoiceStatus)}`" />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.full_names }}
                </span>
                <small class="text-muted">{{ data.item.client_email }}</small>
            </b-media>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(created)="data">
            <span class="text-nowrap">
                {{ data.item.created | formatDate}}
            </span>
        </template>

        <!-- Column: Balance -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">

            <div class="text-nowrap">
                <feather-icon :id="`invoice-row-${data.item.invoiceId}-send-icon`" icon="DownloadIcon" class="cursor-pointer" size="16" @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.invoiceId }})" />
                <b-tooltip title="Send Invoice" class="cursor-pointer" :target="`invoice-row-${data.item.invoiceId}-send-icon`" />

                <feather-icon :id="`invoice-row-${data.item.invoiceId}-preview-icon`" icon="EyeIcon" size="16" class="mx-1" @click="$router.push({ name: 'apps-invoice-preview', params: { id: data.item.invoiceId }})" />
                <b-tooltip title="Preview Invoice" :target="`invoice-row-${data.item.invoiceId}-preview-icon`" />
              
            </div>
        </template>

    </b-table>
    <div class="mx-2 mb-2">
        <b-row>

            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

                <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                </b-pagination>

            </b-col>

        </b-row>
    </div>
</b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
} from 'bootstrap-vue'
import {
    avatarText
} from '@core/utils/filter'
import vSelect from 'vue-select'
import {
    onUnmounted
} from '@vue/composition-api'
import $ from 'jquery'
import store from '@/store'
import useInvoicesList from './useInvoiceList'
import moment from "moment"
import invoiceStoreModule from '../invoiceStoreModule'
import numeral from "numeral"
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip,

        vSelect,
    },
    setup() {
        const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

        // Register module
        if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
        })

        const statusOptions = [
            'Downloaded',
            'Draft',
            'Paid',
            'Partial Payment',
            'Past Due',
        ]

        const {
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refInvoiceListTable,

            statusFilter,

            refetchData,

            resolveInvoiceStatusVariantAndIcon,
            resolveClientAvatarVariant,
        } = useInvoicesList()

        return {
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refInvoiceListTable,

            statusFilter,

            refetchData,

            statusOptions,

            avatarText,
            resolveInvoiceStatusVariantAndIcon,
            resolveClientAvatarVariant,
        }
    },
    data() {
        return {
            isLoading: false,
            startDate: "",
            endDate: "",
            invoices: [],
            total: 0,
            currentPage:1,
            perPage:10,
            columns:[
              { key: 'invoiceId', label: '#', sortable: true },
              { key: 'status', label:'Status', sortable: true },
              { key: 'client', sortable: true },
              { key: 'service_name', label:'Services',sortable: false },
              { key: 'amount', sortable: true, },
              { key: 'created', sortable: true },
              { key: 'actions' },
            ],
            dataMeta:{}
        }
    },
    mounted(){
      this.getallInvoice()
    },

    methods: {
        getallInvoice() {
            let vm = this
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'account/v1/view/invoices',
                type: "get",
                async: true,
                data: {
                    start: vm.startDate,
                    end: vm.endDate,
                    limit: vm.perPage,
                    offset: vm.currentPage
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                     vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    } else {
                        vm.invoices = response.data.data.data
                        vm.dataMeta.of = parseInt(response.data.data.total_count, 10)
                        vm.dataMeta.from = vm.perPage * (vm.currentPage - 1) + (parseInt(response.data.data.total_count, 10) ? 1 : 0)
                        vm.dataMeta.to = vm.perPage  * (vm.currentPage - 1) + parseInt(response.data.data.total_count, 10) 
                        console.log(vm.dataMeta)

                    }
                },
                error: function (jQxhr, status, error) {
                  vm.isLoading = false
                }
            });
        }
    },
    filters: {
       formatDate(value) {
            if (!value) return "";
            return moment(value).format("DD MMM YYYY");
        },
         InvoiceStatus(value) {
            if (value == 50) {
                return "New Invoice"
            } else if (value == 51) {
                return "Partial Paid"
            } else if (value == 52) {
                return "Fully Paid"
            } else {
                return "Overpaid"
            }
        },
        formatAmount: function (value) {
      return numeral(value).format("0,0.00");
    },
    }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
